import "phaser";
import { MainScene } from "./scenes/MainScene";

const config: Phaser.Types.Core.GameConfig = {
   type: Phaser.AUTO,
   width: window.innerWidth,
   height: window.innerHeight,
   parent: document.getElementById('view') || undefined,
   scene: MainScene
}

window.addEventListener("load", () => {
   const _game = new Phaser.Game(config);
   console.log(_game);
});