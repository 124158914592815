const TILE_SIZE = 6;
const HOOD_SIZE = 3;
const RULES: Rule[] = [
   { name: "Life", B: [3], S: [2, 3] },
   { name: "Maze", B: [3], S: [1, 2, 3, 4, 5] },
   { name: "Replicator", B: [1, 3, 5, 7], S: [1, 3, 5, 7] },
   { name: "Fredkin", B: [1, 3, 5, 7], S: [0, 2, 4, 6, 8] },
   { name: "Seeds", B: [ 2 ], S: [] },
   { name: "Live Free Or Die", B: [ 2 ], S: [ 0 ] },
   { name: "Life Without Death", B: [ 3 ], S: [ 0, 1, 2, 3, 4, 5, 6, 7, 8 ] },
   { name: "Flock", B: [ 3 ], S: [ 1, 2 ] },
   { name: "Mazectric", B: [ 3 ], S: [ 1, 2, 3, 4 ] },
   { name: "2x2", B: [ 3, 6 ], S: [ 1, 2, 5 ] },
   { name: "High Life", B: [ 3, 6 ], S: [ 2, 3 ] },
   { name: "Move", B: [ 3, 6, 8 ], S: [ 2, 4, 5 ] },
   { name: "Day And Night", B: [ 3, 6, 7, 8 ], S: [ 3, 4, 6, 7, 8 ] },
   { name: "Dry Life", B: [ 3, 7 ], S: [ 2, 3 ] },
   { name: "Pedestrian Life", B: [ 3, 8 ], S: [ 2, 3 ] },
   { name: "Individualism", B: [ 3 ], S: [ 0 ] }
];
export interface Neighbor {
   status: boolean;
}
export class Cell {
   alive: boolean;
   box: Phaser.Geom.Rectangle;
   constructor(x: number, y: number, width: number, height: number) {
      this.alive = true; 
      this.box = new Phaser.Geom.Rectangle(x, y, width, height);      
   }
}

export interface Rule {
   name: string;
   B: number[];
   S: number[];
}

export class Neighborhood {
   cells: Cell[];
   cellXSize: number;
   cellYSize: number;
   totalXCount: number;
   totalYCount: number;
   x: number;
   y: number;
   width: number;
   height: number;
   edgeCount: number;
   color: number;
   rules: Rule;

   constructor(xStart: number, yStart: number, width: number, height: number, cellXCount: number, cellYCount: number, edgeCount: number = 6, color: number = Phaser.Display.Color.RandomRGB(0, 255).color32, rules: Rule = RULES[0]) {
      this.x = Math.floor(xStart);
      this.y = Math.floor(yStart);
      this.width = Math.floor(cellXCount);
      this.height = Math.floor(cellYCount);
      this.edgeCount = Math.floor(edgeCount);
      this.totalXCount = Math.floor(cellXCount + (edgeCount * 2));
      this.totalYCount = Math.floor(cellYCount + (edgeCount * 2));
      this.cellXSize = width / cellXCount;
      this.cellYSize = height / cellYCount;
      this.color = color;
      this.rules = rules;
      
      let numCells = this.totalXCount * this.totalYCount;      
      this.cells = new Array<Cell>(numCells);

      let rnd: Phaser.Math.RandomDataGenerator = new Phaser.Math.RandomDataGenerator();
      
      // initialize randomly alive cells and determine neighbors of each cell
      const xOffset = this.cellXSize * this.edgeCount;
      const yOffset = this.cellYSize * this.edgeCount;
      for (let y = 0; y < this.totalYCount; y++) {
         for (let x = 0; x < this.totalXCount; x++) {
            const index = this.getCellIndex(x, y);
            this.cells[index] = new Cell(xStart + x * this.cellXSize - xOffset, yStart + y * this.cellYSize - yOffset, this.cellXSize, this.cellYSize)
            this.cells[index].alive = rnd.integerInRange(0, 1) === 0;            
         }
      }

      for (let y = 0; y < this.totalYCount; y++) {
         for (let x = 0; x < this.totalXCount; x++) {
            this.getAliveNeighbors(x, y);
         }
      }

   }
   shouldBeAlive(x: number, y: number): boolean {
      const index = this.getCellIndex(x, y);      
      const neighborCount: number = this.getAliveNeighbors(x, y);
      //console.log(`Neighbors alive at ${x}, ${y}: ${neighborCount}`);
      // Decide the rules based on whether current status is alive or not
     
      if (this.cells[index].alive) {
         // If alive with less than 2 or more than 3 neighbors, die
         return (neighborCount < 2 || neighborCount > 3) ? false : true;
      } else {
         // If dead with 3 or 6 neighbors, become alive ("spawn" new cell)
         return (neighborCount === 3 || neighborCount === 6) ? true : false; 
      }      
   }

   processRule(x: number, y: number, rules: Rule): boolean {
      const index = this.getCellIndex(x, y);
      const neighborCount: number = this.getAliveNeighbors(x, y);
      if (this.cells[index].alive) {
         // if cell is alive and neighborCount = S (survive), survive! otherwise, die 
         return rules.S.filter(rule => neighborCount === rule).length > 0;
      } else {
         // if cell is dead and neighborCount = B (birth), create new one! otherwise, stay dead
         return rules.B.filter(rule => neighborCount === rule).length > 0;
      }
   }

   isLeftEdge(x: number): boolean {
      return x <= 0;
   }
   isRightEdge(x: number): boolean {
      return x >= this.totalXCount;
   }
   isTopEdge(y: number): boolean {
      return y <= 0;
   }
   isBottomEdge(y: number): boolean {
      return y >= this.totalYCount;
   }
   getCellIndex(x: number, y: number): number {
      return Phaser.Math.Clamp(y, 0, this.totalYCount - 1) * this.totalXCount + Phaser.Math.Clamp(x, 0, this.totalXCount - 1);
   }
   getCell(x: number, y: number): Cell {      
      return this.cells[this.getCellIndex(x, y)];
   }
   setCell(x: number, y: number, cell: Cell): void {      
      this.cells[this.getCellIndex(x, y)] = cell;
   }
   getAliveNeighbors(x: number, y: number): number {      
      const neighbors: Neighbor[] = [];
      if (!this.isTopEdge(y)) {
         if (!this.isLeftEdge(x)) neighbors.push({ status: this.getCell(x - 1, y - 1).alive });
         if (!this.isRightEdge(x)) neighbors.push({ status: this.getCell(x + 1, y - 1).alive });
         neighbors.push({ status: this.getCell(x, y - 1).alive });
      }
      if (!this.isLeftEdge(x)) neighbors.push({ status: this.getCell(x - 1, y).alive });
      if (!this.isRightEdge(x)) neighbors.push({ status: this.getCell(x + 1, y).alive });
      if (!this.isBottomEdge(y)) {
         if (!this.isLeftEdge(x)) neighbors.push({ status: this.getCell(x - 1, y + 1).alive });
         if (!this.isRightEdge(x)) neighbors.push({ status: this.getCell(x + 1, y + 1).alive });
         neighbors.push({ status: this.getCell(x, y + 1).alive });
      }
      
      return neighbors.filter(neighbor => neighbor.status === true).length;
   }
}
export class MainScene extends Phaser.Scene {   

   neighborhoods: Neighborhood[];   
   elapsedTime: number;
   canvas!: Phaser.GameObjects.Graphics;
   drawing: boolean;
      
   constructor() {
      super({
         key: 'MainScene'
      });
      this.neighborhoods = new Array<Neighborhood>();
      this.elapsedTime = 0;
      this.drawing = false;

   }

   preload(): void {
      
   }

   create(): void {
      // Create initial cell conditions (one big neighborhood for now)      
      this.canvas = this.add.graphics({
         fillStyle: {
            color: 0xff0000
         },
         lineStyle: {
            color: 0x333333
         }
      });
      //console.log(`Viewport size: ${this.game.renderer.width} x ${this.game.renderer.height}`);      
      const hoodWidth = this.game.renderer.width / HOOD_SIZE;
      const hoodHeight = this.game.renderer.height / HOOD_SIZE;
      const ruleDeck = RULES;
      for (let y = 0; y < HOOD_SIZE; y++) {
         for (let x = 0; x < HOOD_SIZE; x++) {            
            const [ rule ]: Rule[] = ruleDeck.splice(Math.floor(Math.random() * RULES.length), 1);
            const hood = new Neighborhood(x * hoodWidth, y * hoodHeight, hoodWidth, hoodHeight, hoodWidth / TILE_SIZE, hoodHeight / TILE_SIZE, 6, Phaser.Display.Color.RandomRGB().color32, rule);
            const hoodIndex = y * HOOD_SIZE + x;
            this.neighborhoods[hoodIndex] = hood;
         }
      }
      //const rule: Rule = RULES[ruleKeys[ ruleKeys.length * Math.random() << 0 ]];  
      //this.neighborhoods.push(new Neighborhood(0, 0, this.game.renderer.width, this.game.renderer.height, hoodWidth / TILE_SIZE, hoodHeight / TILE_SIZE, 6, Phaser.Display.Color.RandomRGB().color32, rule));      

      //this.add.grid(0, 0, this.game.renderer.width, this.game.renderer.height, 16, 16, 0x440000, 1, 0xFF0000, 1);      
      const displayRule = this.add.text(0, 0, "", {
         fontFamily: 'Consolas',
         fontSize: 32,
         color: '#ffffff'
      });
      displayRule.setShadow(2, 2, '#000000');
      displayRule.alpha = 0.75;

      this.input.on('pointermove', (pointer: Phaser.Input.Pointer) => {         
         let hoodX = Math.floor(pointer.x / hoodWidth);
         let hoodY = Math.floor(pointer.y / hoodHeight);
         let hood = this.neighborhoods[hoodY * HOOD_SIZE + hoodX] || this.neighborhoods[0];            
         if (this.drawing) {
            let x = Math.floor((pointer.x - hood.x) / hood.cellXSize) + hood.edgeCount;
            let y = Math.floor((pointer.y - hood.y) / hood.cellYSize) + hood.edgeCount;
            this.drawCell(hood, x, y);            
         }
         displayRule.text = hood.rules.name; 
         displayRule.x = hood.x;
         displayRule.y = hood.y;        
      })
      this.input.on('pointerdown', (pointer: Phaser.Input.Pointer) => {
         this.drawing = true;
         let hoodX = Math.floor(pointer.x / hoodWidth);
         let hoodY = Math.floor(pointer.y / hoodHeight);
         let hood = this.neighborhoods[hoodY * HOOD_SIZE + hoodX] || this.neighborhoods[0];         
         let x = Math.floor((pointer.x - hood.x) / hood.cellXSize) + hood.edgeCount;
         let y = Math.floor((pointer.y - hood.y) / hood.cellYSize) + hood.edgeCount;      
         //console.log(`Hood x: ${hoodX}, hood y: ${hoodY}, hood index: ${hoodY * hoodSize + hoodX}, cell x: ${x}, cell y: ${y}`);   
         this.drawCell(hood, x, y);      
         //console.log(`Neighbors alive at ${x}, ${y}: ${hood.getAliveNeighbors(x, y)}`);
     });
     this.input.on('pointerup', (_pointer: Phaser.Input.Pointer) => {
        this.drawing = false;        
     });
     
   }

   drawCell(hood: Neighborhood, x: number, y: number) {
      const cell = hood.getCell(x, y);         
      cell.alive = true;
      hood.setCell(x, y, cell);
   }

   update(): void {
      if (!this.drawing) this.processStage();
      this.renderStage();
   }

   renderStage(): void {
      this.canvas.clear();
      
      this.neighborhoods.forEach(hood => {
         //this.canvas.lineStyle(4, 0x888888);
         //this.canvas.strokeRectShape(new Phaser.Geom.Rectangle(hood.x, hood.y, hood.width * hood.cellXSize, hood.height * hood.cellYSize));
         //this.canvas.lineStyle(1, 0x333333);
         // Draw the cells         

         for (let y = hood.edgeCount; y < hood.height + hood.edgeCount; y++) {
            for (let x = hood.edgeCount; x < hood.width + hood.edgeCount; x++) {               
               const box = hood.getCell(x, y).box;                            
               if (hood.getCell(x, y).alive) {
                  this.canvas.fillStyle(hood.color, .5);
               } else {
                  this.canvas.fillStyle(0x000000, 0);
               }
               this.canvas.fillRectShape(box);
               //this.canvas.strokeRectShape(box);
            }
         }
      });
   }
   processStage(): void {      
      this.neighborhoods.forEach(hood => {
            
         const nextCellStage: boolean[] = [];

         // Determine the next step for each cell
         for (let y = 0; y < hood.totalYCount; y++) {
            for (let x = 0; x < hood.totalXCount; x++) {
               nextCellStage.push(hood.processRule(x, y, hood.rules));
            }
         }

         // Apply the changes once all next steps have been determined
         for (let i = 0; i < nextCellStage.length; i++) {
            hood.cells[i].alive = nextCellStage[i] === true;
         }


      });      
   }
}